<template>
  <div class="relative w-full flex">
    <select
      :id="id"
      ref="select"
      :value="modelValue"
      :required="required"
      class="w-full font-sans text-md border-none rounded text-left min-w-13 pl-4 pr-8 focus:shadow-focus focus:outline-none appearance-none disabled:opacity-35"
      :class="[
        color === 'white' ? 'bg-white' : 'bg-gray-100',
        sizeClass,
        invalid && 'shadow-focus shadow-red-text',
        untouched && 'shadow-none',
      ]"
      :name="name"
      :disabled="disabled"
      @input="onInput"
    >
      <slot />
    </select>
    <SvgIcon
      class="pointer-events-none absolute top-1/2 right-3 -mt-2 w-4 h-4"
      icon="chevron-down"
      aria-hidden="true"
      :class="disabled && 'disabled:opacity-35'"
    />
  </div>
</template>

<script lang="ts" setup>
  interface Props {
    id?: string
    disabled?: boolean
    required?: boolean
    name?: string
    modelValue: string
    invalid?: boolean
    size?: "medium" | "small"
    color?: "gray" | "white"
  }

  const props = withDefaults(defineProps<Props>(), {
    id: "",
    disabled: false,
    required: false,
    name: "",
    modelValue: "",
    invalid: false,
    size: "medium",
    color: "gray",
  })

  const sizeClass = computed(() => {
    switch (props.size) {
      case "small":
        return ["h-8"]
      case "medium":
        return ["text-md", "px-4", "h-11"]
      default:
        return []
    }
  })

  const emit = defineEmits(["update:modelValue"])
  const untouched = ref(true)

  const onInput = (e: Event) => {
    untouched.value = false
    emit("update:modelValue", (e.target as HTMLInputElement).value)
  }
</script>
